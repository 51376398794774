/* global */
a {
  text-decoration: none;
  color:black;
}

ul li {
  list-style-type: none;
}

.header-left:hover, .side-work:hover, .side-about:hover {
  color:grey;
  transform: all 0.3s ease;
}

.clickable {
  cursor: pointer;
  font-weight: 400;
}
/* header */
.header {
  background-color: white;
  z-index:20;
  color: black;
  font-size: 1.4em;
  max-width: 2000px;
  height: 70px !important;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items:center;
  justify-content: space-between;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

.header-left {
  margin-left: 10%;
}


.header-right {
  margin-right: 10%;
}

/* main */
.main-list {
  display: flex;
  flex-direction: column;
}

.bio {
  margin: 22% auto;
  min-width: 70vw;
  height: 90vh;
  max-height:100px;
  text-align: center;
}

.bio p  {
  font-size: 2em;
  max-width: 1200px;
  line-height: 150%;
}

.bio-work-each {
  padding:2% 0;
  width: 100%;
  /* background-color: lightgray; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.bio-work-each img {
  width: 100%;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.hidden {
  visibility: hidden;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color:black;
}

.bio-work {
  display: flex;
  flex-wrap: wrap;
  margin-left: 4%;
}

.bio-work>div {
  flex: 0 50%;
  border: white solid;
  box-sizing:border-box
}

/* side-about */
/* will be hidden in small screen */
.side-about, .side-about-ex {
  overflow-x: hidden;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 10%;
  max-width: 60px;
  height: 100%;
  z-index: 99;
  display: block;
  border-left: 2px solid black;
}

.side-about-ex {
  border-left:none;
}

.side-about h2, .side-about-ex h2 {
  margin-top: 45vh;
  writing-mode: vertical-lr;
  text-orientation: mixed;
}

/* slide in animation later */
.side-about-extended {
  overflow-x: hidden;
  background-color: white;
  position: fixed;
  top: 0;
  right: 60px;
  width: 60%;
  max-width: 600px;
  height: 100%;
  z-index: 33;
  display: block;
  border-left: 2px solid black;
  padding-top: 5%;
  padding-right: 5%;
}

.side-about-extended p, .side-about-extended a {
  font-size: 1.2em;
  text-indent: 2em;
  line-height: 150%;
  margin: auto 15%;
}

.side-about-extended a {
  display: block;
}

/* side-work */
/* will be hidden in small screen */
.side-work, .side-work-ex {
  overflow-x: hidden;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 10%;
  max-width: 60px;
  height: 100%;
  z-index: 99;
  display: block;
  border-right: 2px solid black;
}

.side-work-ex {
  border-right: none;
}

.side-work h2, .side-work-ex h2 {
  margin-top: 45vh;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  text-orientation: mixed;
}


/* slide in animation later */
.side-work-extended {
  overflow-x: hidden;
  background-color: white;
  position: fixed;
  top: 0;
  left: 60px;
  width: 60%;
  max-width: 600px;
  height: 100%;
  z-index: 33;
  display: block;
  border-right: 2px solid black;
  padding-top: 5%;
  padding-left: 5%;
}

.side-work-extended h2 {
  font-size: 1.1em;
}
.side-work-extended ul li {
  line-height: 140%;
  font-size: 1.2em;
}

/* footer */

.footer {
 background-color: black;
 color:white;
 max-width: 2000px;
 display: flex;
 flex-direction: row;
 align-content: center;
 align-items:center;
 justify-content: space-around;
}

.footer a {
  color:white;
  display: block;
}

.footer-bio, .footer-links {
  margin: 5%;
  padding: 5%;
  width: 30%;
  max-width: 400px;
}

.footer-bio {
  line-height: 200%;
  font-size: 1.2em;
  text-indent: 2em;
}

.footer-links {
  line-height: 150%;
}

/* arrow up */
.arrow-container {
  position: fixed;
  height: 40px;
  width: 40px;
  bottom: 140px;
  right: 170px;
  z-index:2000;
}

.arrow {
  color: black;
}

.arrow-container:hover {
  height: 50px;
  width: 50px;
}

.arrow-container-hidden {
  visibility:none;
}